import React from 'react'
import coin from '../../assets/images/coin.png'
import coin1 from '../../assets/images/coin1.png'
import coin2 from '../../assets/images/coin2.png'

import './Section5.css'
import Bg from '../../assets/images/bg.jpeg'
function Section5() {
  return (
    <section className='section5' id='section5'>
      <h1 className='title-shadow-design'>Team</h1>
      <h1 className='section5-mainheading1'>Team</h1>
      <div className='underline-heading' />
      <div className='section5-maincontainer'>
        <div className='section5-maindiv-subsection row d-flex justify-content-between col-12'>
          <div className='section5-main-team col-12 col-md-6 mb-md-5 col-lg-4'>
            <div className='orange-frame'>
              <p>founder</p>{' '}
            </div>
            <div className='img-container'>
              <img src={coin} alt='' />
              <h3>
                El Namus - <span>Founder</span>
              </h3>
              <p style={{textAlign: 'justify'}}>
                Passionate about art and Roman antiquity, El Namus has been most
                recently focused on the NFT space, with releases on major
                platforms like SuperRare, KnownOrigin, and AsyncArt.
              </p>
            </div>
          </div>
          <div className='section5-main-team col-12 col-md-6 mb-md-5 col-lg-4 section5-main-team-m'>
            <div className='orange-frame'>
              <p>founder</p>
            </div>
            <div className='img-container'>
              <img src={coin1} alt='' />
              <h3>
              Lucius - <span>3D Artist </span>
              </h3>
              <p style={{textAlign: 'justify'}}>
              Lucius is a genius Artist, after studying Art in Paris he entered the video game industry before making a switch into concept design and marketing while pursuing his own artistic career into the NFT world.
              </p>
            </div>
          </div>
          <div className='section5-main-team col-12 col-md-6 col-lg-4'>
            <div className='orange-frame'>
              <p>founder</p>
            </div>
            <div className='img-container'>
              <img src={coin2} alt='' />
              <h3>
              Marcus - <span>Community Manager</span>
              </h3>
              <p style={{textAlign: 'justify'}}>
              Marcus has several years of experience in SEO, influencer and other marketing. is always bringing the good vibes to the table and has his new focus set on NFT Community
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section5
