import React,{useState} from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Section1 from "./components/Section1/Section1";
import Section2 from "./components/Section2/Section2";
import Section3 from "./components/Section3/Section3";
import Section4 from "./components/Section4/Section4";
import Section5 from "./components/Section5/Section5";
import Footer from "./components/Footer/Footer";
import BackToTop from "./components/ScrollBtn/ScrollBtn"
const App = () => {

  const [enableConnect,setConnect] = useState(true)

  return (
  <div className="app">
      <Header/>
       <Section1 setConnect={setConnect}/>
       <Section2 enableConnect={enableConnect}/>
       <Section3/>
       <Section4/>
       <Section5/>
       <BackToTop/>
       <Footer/>
       
  </div>

    );
}

export default App;
