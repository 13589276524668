import React, { useState, useEffect } from 'react'
import './Section1.css'
import BG from '../../assets/images/Group1.png'
import Countdown from '../CountDown/CountDown'

function Section1(props) {
  const currentDate = new Date()
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear()

      const [CONFIG, SET_CONFIG] = useState({})


      const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        const config = await configResponse.json()
        SET_CONFIG(config)
      }
    
      useEffect(() => {
        getConfig()
      }, [])
    



  return (
    <section
      className='section1'
      id='section1'
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${BG})`,
        backgroundPosition: 'center center',
      }}
    >
      <div className='section1__contents'>
          <div className='section1-glasseffect'>
               {CONFIG.COUNTDOWN_DISPLAY ? <Countdown setConnect={props.setConnect} date={`${year}${CONFIG.COUNTDOWN_DATE}`} /> : null}
              <p className='section1__description'>
              When the past and the future become one
              </p>
              <p className='section1__description'>
              Get your lucky charm get your Lucky Phallus 
              </p>             
          </div>
      </div>

      <div className='section1__fadeBottom'></div>
    </section>
  )
}

export default Section1
