import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <footer>
      <ul class='navbar-nav navbar-nav-icons-mobile'>
        <li class='nav-item'>
          <a class='nav-link header-social-link discord' href='https://discord.com/invite/QgeawZkxS3'>
            <i class='fab fa-discord'></i>
          </a>
        </li>
        <li class='nav-item'>
          <a class='nav-link header-social-link twitter' href='https://twitter.com/LuckyPhallus'>
            <i class='fab fa-twitter'></i>
          </a>
        </li>
        {/* <li class='nav-item'>
              <a class='nav-link header-social-link telegram-plane' href='#'>
                <i class='fab fa-telegram-plane'></i>
              </a>
            </li> */}
      </ul>
      <div class='footer-maincontainer py-2'>
        <div class='container text-center'>
          <p class='text-muted mb-0 py-2'>
            © 2021 Company All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
