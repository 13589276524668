import React from 'react'

import './Section4.css'
import Bg from '../../assets/images/bg.jpeg'
function Section4() {
  return (
    <section className='section4' id='section4'>
      <h1 className='title-shadow-design'>Road Map</h1>
      <h1 className='section4-mainheading1'>Road Map</h1>
      <div className='section4-maincontainer'>
        <div class=' diamond-shape'>
          <div className='number-counter'><span>1</span></div>
          <div class='item-count'>10%</div>
          <div className='section4-card'>
            Creation of unique 3D digital antique piece of art. Generation of
            Lucky Phallus NFT using AI.
          </div>
        </div>
        <div class=' diamond-shape'>
          <div className='number-counter'><span>2</span></div>
          <div class='item-count'>30%</div>
          <div className='section4-card'>
            Set of NFTs airdropped to loyal early supporters, fanbase, and random
            followers.
          </div>
        </div>
        <div class=' diamond-shape'>
          <div className='number-counter'><span>3</span></div>
          <div class='item-count'>50%</div>
          <div className='section4-card'>
            Lucky Phallus website MINT countdown is officially started.
          </div>
        </div>
        <div class=' diamond-shape'>
          <div className='number-counter'><span>4</span></div>
          <div class='item-count'>70%</div>
          <div className='section4-card'>
          Preparation of the Lucky Phallus Token launchpad, WL granted for NFT holders.
          </div>
        </div>

        <div class=' diamond-shape'>
          <div className='number-counter'><span>5</span></div>
          <div class='item-count'>90%</div>
          <div className='section4-card'>
          Selection of best metaverse to develop our VR experience (Pompei site, Red district ...) and P2E game.
          </div>
        </div>

        <div class=' diamond-shape'>
          <div className='number-counter'><span>6</span></div>
          <div class='item-count'>100%</div>
          <div className='section4-card'>
          Enjoy advantages (passive income, private club access ...) and MEGA Giveaway of 100.000$ after sold out.
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section4
