import React from 'react'
import './Section3.css'
import Phallas1 from '../../assets/images/3.png'
import Phallas2 from '../../assets/images/4.png'

function Section3() {
  return (
    <section className='section3' id='section2'>
      <div className='section3-maincontainer'>
        <div className='section3-content-container1'>
          <div className='section3-image-container1-maindiv'>
            <div className='section3-image-container1'>
              <div className='section-2-vector' />
            </div>
          </div>
          <div className='section3-para-container1'>
            <h1 className='title-shadow-design'>Why PHALLUS</h1>
            <h1 className='section3-heading2'>Why PHALLUS</h1>
            <span className='section-3-line' />
            <p style={{textAlign: 'justify'}}>
              The phalluses were more symbols, not advertisements for sex. The
              phallus in many civilizations and since Antiquity are the symbols
              of good health, virility, and fertility. At Pompei archeological
              site the first lucky phallus was discovered, the original
              sculpture still today visible at Napoli national museum
            </p>
          </div>
        </div>
        <div className='section3-content-container2'>
          <div className='section3-para-container2'>
            <p style={{textAlign: 'justify'}}>
            Romans Used to Ward Off Sickness With Flying Penis Amulets.
            This antique is now a digital piece of art NFT, each one is expertly designed, algorithmically compiled, and handsomely shaped.
            It will bring wealth, prosperity to each holder and will ward you off from the evil eye.
            </p>
          </div>
          <div className='section3-image-container2-maindiv'>
            <div className='section3-image-container2'></div>
            <div className='section-2-vector' />
          </div>
        </div>
        <h1 className='title-shadow-design' id='section3'>About Lucky Phallus design</h1>
        <h1 className='section3-heading4'>About Lucky Phallus design</h1>
        <div className='section3-maindiv-subsection' >
          <div className='section3-maindiv-secondsubsection'>
            <div className='section2-sidepillar' />
            <div className='section3-subsection-container'>
              <div className='section3-secondsubsection1'>
                <div className='section3-secondsubsection1-box1'></div>
                <div className='section3-secondsubsection1-box2 section3-secondsubsection1-stack-top'></div>
                <div className='section3-border-bottom'></div>
              </div>
              <p className='section3-secondsubsection-text'>
                A renderer is a carefully engineered program based on multiple
                disciplines, including light physics, visual perception,
                mathematics, and software development.{' '}
              </p>
            </div>

            <div className='section3-subsection-container'>
              <div className='section3-secondsubsection1'>
                <div className='section3-secondsubsection1-box1'></div>
                <div className='section3-secondsubsection1-box3 section3-secondsubsection1-stack-top'></div>
                <div className='section3-border-bottom'></div>
              </div>
              <p className='section3-secondsubsection-text'>
                Our unique 10.000 Lucky Phallus 3D NFT Were developed, with
                highly professional Rendering design.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section3
