import React,{useEffect} from 'react'
import "./ScrollBtn.css"

function ScrollBtn() {
    
    let backToTopButton;
    
    const showBackToTopBtn = () => {
        if (window.pageYOffset > 300) {
            backToTopButton.classList.add("show-back-to-top-button")
        } else {
            backToTopButton.classList.remove("show-back-to-top-button");
        }
    }
  
    const scrollToTop = () =>{
      window.scrollTo(0,0)
    }
    
    useEffect(() => {
    
      backToTopButton = document.getElementById("back-to-top-button");
            window.addEventListener('scroll', showBackToTopBtn);
    
        return () => {
        window.removeEventListener('scroll', showBackToTopBtn);
    
        }
    }, [])
    


    return (
        <div onClick={scrollToTop} id="back-to-top-button">
        </div>
    )
}

export default ScrollBtn
