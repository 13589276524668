import React, { useEffect, useState, useRef } from 'react'
import './Header.css'
import HeaderImage from '../../assets/images/2.png'

const Header = () => {
  let navbar
  let sticky

  const handleSticky = () => {
    if (window.pageYOffset > sticky + 10) {
      navbar.classList.add('sticky', 'navbar-background-dark')
    } else {
      navbar.classList.remove('sticky', 'navbar-background-dark')
    }
  }

  useEffect(() => {
    navbar = document.getElementById('main-navigation')
    sticky = navbar.offsetTop
    window.addEventListener('scroll', handleSticky)

    return () => {
      window.removeEventListener('scroll', handleSticky)
    }
  }, [])

  return (
    <nav id='main-navigation' class='navbar navbar-light navbar-expand-lg '>
      <div class='container-fluid'>
        <a class='navbar-brand' href='#'>
          Lucky Phallus
        </a>
        <button
          class='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span class='navbar-toggler-icon'></span>
        </button>
        <div class='collapse navbar-collapse' id='navbarSupportedContent'>
          <button className='header-btn header-draw-border mobile-header-btn'>
            OpenSea
          </button>

          <ul class='navbar-nav me-auto'>
            <li class='nav-item'>
              <a class='nav-link' href='#section1'>
                Home
              </a>
            </li>

            <li class='nav-item'>
              <a class='nav-link' href='#section2'>
                Story
              </a>
            </li>

            <li class='nav-item'>
              <a class='nav-link' href='#section3'>
                Design
              </a>
            </li>

            <li class='nav-item'>
              <a class='nav-link' href='#section4'>
                Roadmap
              </a>
            </li>

            <li class='nav-item'>
              <a class='nav-link' href='#section5'>
                Team
              </a>
            </li>
          </ul>

          <ul
            class='navbar-nav flex-nowrap mb-2 mb-lg-0'
            id='social-links-header'
          >
            <li class='nav-item'>
              <a class='nav-link header-social-link discord' href='https://discord.com/invite/QgeawZkxS3' target="_blank">
                <i class='fab fa-discord'></i>
              </a>
            </li>
            <li class='nav-item'>
              <a class='nav-link header-social-link twitter' href='https://twitter.com/LuckyPhallus' target="_blank">
                <i class='fab fa-twitter'></i>
              </a>
            </li>
            {/* <li class='nav-item'>
              <a class='nav-link header-social-link telegram-plane' href='#'>
                <i class='fab fa-telegram-plane'></i>
              </a>
            </li> */}
          </ul>

          <button className='header-btn header-draw-border desktop-header-btn'>
            <a style={{textDecoration:"none"}} href='https://opensea.io/collection/luckyphallus' target="_blank">OpenSea</a>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default Header
