import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from '../../redux/blockchain/blockchainActions'
import { fetchData } from '../../redux/data/dataActions'
import './Section2.css'
import axios from 'axios'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input

function App() {
  const dispatch = useDispatch()
  let gasPrice = 0
  const blockchain = useSelector((state) => state.blockchain)
  const data = useSelector((state) => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`)
  const [successPayment, setSuccessPayment] = useState(false)
  const [receipt, setReceipt] = useState('')
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: false,
  })

  const claimNFTs = async() => {
    if (mintAmount <= 0) {
      alert('Mint amount must be greater than zero')
      return
    }

    await axios
      .post(
        'https://polygon-mainnet.g.alchemy.com/v2/uuI0Hd0Fuh7xMhQ6_bxzaP7wAkP-AK4F',
        {
          jsonrpc: '2.0',
          method: 'eth_gasPrice',
          params: [],
          id: 0,
        }
      )
      .then((res) => {
        console.log(res.data.result)
        let price = res.data.result

        let currentPrice = parseInt(price, 16)
        gasPrice = currentPrice * 1.5
        console.log("current",currentPrice)
        console.log("gas",gasPrice)
      })
      .catch((err) => {
        console.log(err)
      })

    let cost = CONFIG.WEI_COST
    let gasLimit = CONFIG.GAS_LIMIT
    let totalCostWei = String(cost * mintAmount)
    let totalGasLimit = String(gasLimit * mintAmount)
    console.log('Cost: ', totalCostWei)
    console.log('Gas limit: ', totalGasLimit)
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`)
    setClaimingNft(true)
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        gasPrice: gasPrice,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once('error', (err) => {
        console.log(err)
        setFeedback('Sorry, something went wrong please try again later.')
        setClaimingNft(false)
      })
      .then((receipt) => {
        console.log(receipt)
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        )
        setReceipt(receipt)
        setSuccessPayment(true)
        setClaimingNft(false)
        dispatch(fetchData(blockchain.account))
      })
      .catch((err) => {
        console.log('Custom2', err)
        setClaimingNft(false)
      })
  }

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    getData()
  }, [blockchain.account])

  let [mintAmount, setMintAmount] = useState(0)

  const mintAmountHandler = (e) => {
    if (parseInt(e.target.value) > 20) {
      alert('Maximum value allowed to mint is 20')
      setMintAmount(20)
      return
    }

    setMintAmount(e.target.value.replace(/[^0-9]/g, ''))
  }

  const mintIncreaseHandler = () => {
    if (parseInt(mintAmount) < 20) {
      setMintAmount(parseInt(mintAmount) + 1)
    } else if (mintAmount === '') {
      setMintAmount(1)
    }
  }

  const mintDecreaseHandler = () => {
    if (parseInt(mintAmount) > 0) {
      setMintAmount(parseInt(mintAmount) - 1)
    }
  }

  return (
    <section className='section2' id='section2'>
      <div className='section2-sidepillar'></div>

      <div class='section2-main-container'>        
      <div className='section2-leftside-box'></div>
            <div className='section2-rightside-box'></div>
        <div class='section2-heading'>
          <h1 class='title-shadow-design'> MINT LUCKY PHALLUS</h1>
          <h1 class='section2-heading__title2'> MINT LUCKY PHALLUS</h1>
          <p class='section2-heading__credits'>
            <span>
              {data.totalSupply} NFT from 10000 already minted ({' '}
              {data.totalSupply} / {CONFIG.MAX_SUPPLY} )
            </span>
          </p>

          <div style={{ marginTop: '12px' }}>
            <a
              className='section2-heading__credits-contract'
              target={'_blank'}
              href={CONFIG.SCAN_LINK}
            >
              Contract
              {/* {truncate(CONFIG.CONTRACT_ADDRESS, 15)} */}
            </a>
          </div>
        </div>

        <div class='section2-cards'>
          <div class='section2-card section2-card-4'>
            <div className='section2-input-container'>
              <h6>Enter Amount</h6>
              <input
                onChange={(event) => mintAmountHandler(event)}
                value={mintAmount}
              />
            </div>

            <div className='section2-buttons'>
                <button
                  onClick={() => mintDecreaseHandler()}
                  class='section2-btn '
                >
                  <i class='fa fa-minus-circle' aria-hidden='true'></i>
                </button>
                <button
                  onClick={() => mintIncreaseHandler()}
                  class='section2-btn '
                >
                  <i class='fa fa-plus-circle' aria-hidden='true'></i>
                </button>
              {/* <button className="section2-button">Mint Now</button> */}
            </div>

            <div className='section2-mint-cost'>
              <br />
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <h1 style={{ textAlign: 'center' }}>The sale has ended.</h1>
                  <p style={{ textAlign: 'center' }}>
                    You can still find {CONFIG.NFT_NAME} on
                  </p>
                  <br />
                  <a target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </a>
                </>
              ) : (
                <>
                  <h1 style={{ textAlign: 'center' }}>
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{' '}
                    {CONFIG.NETWORK.SYMBOL}.
                  </h1>
                  <p className='section-2-text'>Excluding gas fees.</p>
                  <br />
                  {blockchain.account === '' ||
                  blockchain.smartContract === null ? (
                    <div>
                      <p
                       className='section-2-text'
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </p>
                      <br />
                      <button
                        className='section2-button'
                        onClick={(e) => {
                          dispatch(connect())
                          getData()
                        }}
                        disabled={CONFIG.CONNECT_BUTTON}
                      >
                        Connect
                      </button>
                      {blockchain.errorMsg !== '' ? (
                        <>
                          <br />
                          <p
                            style={{
                              textAlign: 'justify',
                            }}
                          >
                            {blockchain.errorMsg}
                          </p>
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <p
                        style={{
                          textAlign: 'justify',
                        }}
                      >
                        {feedback}
                      </p>
                      <br />

                      {successPayment ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'justify',
                            margin: '20px',
                          }}
                        >
                          <a
                            style={{
                              marginRight: '30px',
                              textDecoration: 'none',
                              color: 'white',
                            }}
                            target='_blank'
                            href={CONFIG.MARKETPLACE_LINK}
                          >
                            Lucky Phallas Collection
                          </a>
                          <a
                            style={{
                              marginRight: '30px',
                              textDecoration: 'none',
                              color: 'white',
                            }}
                            target='_blank'
                            href={`https://polygonscan.com/tx/${receipt.transactionHash}`}
                          >
                            View Transaction Id
                          </a>
                          <a
                            style={{ textDecoration: 'none', color: 'white' }}
                            target='_blank'
                            href={`https://opensea.io/${receipt.from}`}
                          >
                            My Collection
                          </a>
                        </div>
                      ) : null}

                      <br />
                      <div>
                        <button
                          className='section2-button'
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            claimNFTs()
                            getData()
                          }}
                        >
                          {claimingNft ? 'BUSY' : 'BUY'}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              <br />
              <br />
              <div>
                <p
                  style={{
                    textAlign: 'justify',
                    margin: '0px',
                    color: '#fff',
                  }}
                >
                  Please make sure you are connected to the right network (
                  {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please
                  note: Once you make the purchase, you cannot undo this action.
                </p>
                <br />
                <p
                  style={{
                    textAlign: 'justify',
                    margin: '0px',
                    color: '#fff',
                  }}
                >
                  We have set the gas limit to {CONFIG.GAS_LIMIT} for the
                  contract to successfully mint your NFT. We recommend that you
                  don't lower the gas limit.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default App
